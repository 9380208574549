import React from "react"
import PropTypes from "prop-types"
import * as styles from "./PageTitle.module.scss"
import UniversalLink from "./UniversalLink"
import MaterialIcon from "components/common/ui/MaterialIcon"

const PageTitleBreadcrumb = ({ parentNode }) => {
  return (
    parentNode?.title && (
      <>
        {parentNode.parent?.node && (
          <PageTitleBreadcrumb parentNode={parentNode.parent?.node} />
        )}
        <MaterialIcon className={styles.caret}>󰅁</MaterialIcon>
        <UniversalLink to={parentNode.uri} className={styles.breadcrumbLink}>
          {parentNode.title}
        </UniversalLink>
      </>
    )
  )
}

PageTitleBreadcrumb.propTypes = {
  parentNode: PropTypes.object,
}

export default PageTitleBreadcrumb
