import React from "react"
import PropTypes from "prop-types"
import * as styles from "./PageTitle.module.scss"
import { Container } from "react-bootstrap"
import PageTitleBreadcrumb from "./PageTitleBreadcrumb"

const PageTitle = ({ title, parentNode }) => {
  return (
    <Container className={styles.container}>
      {parentNode && (
        <h6 className={styles.items}>
          <PageTitleBreadcrumb parentNode={parentNode} />
        </h6>
      )}
      <h1>{title}</h1>
    </Container>
  )
}

PageTitle.propTypes = {
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  parentNode: PropTypes.object,
}

export default PageTitle
