import React, { useMemo } from "react"
import PropTypes from "prop-types"
import * as styles from "./PositionConsentSubForm.module.scss"
import WysiwygContent from "../ui/WysiwygContent"
import { Col, Form, Row } from "react-bootstrap"
import CustomFormLabel from "../forms/CustomFormLabel"
import { Field, useFormikContext } from "formik"
import RequiredCheckboxLabel from "../forms/RequiredCheckboxLabel"

const PositionConsentSubForm = ({
  statementSectionSublabel,
  applicantNameLabel,
  applicantNamePlaceholder,
  mothersNameLabel,
  mothersNamePlaceholder,
  statementIntro,
  declareCheckboxLabel,
  declareText,
  agreeCheckboxLabel,
  agreeText,
  understoodCheckboxLabel,
  understoodText,
  disableApplicantName,
}) => {
  const { touched, errors } = useFormikContext()

  const declareContent = useMemo(
    () => declareCheckboxLabel && declareCheckboxLabel.length > 0
  )
  const agreeContent = useMemo(
    () => agreeCheckboxLabel && agreeCheckboxLabel.length > 0
  )
  const understoodContent = useMemo(
    () => understoodCheckboxLabel && understoodCheckboxLabel.length > 0
  )

  return (
    <>
      <div className={styles.firstConsentWrapper}>
        <WysiwygContent
          dangerouslySetInnerHTML={{
            __html: statementSectionSublabel,
          }}
          className={styles.wysiwyg}
        />
      </div>
      <Row className="justify-content-center">
        <Col xs={10} sm={12} lg={6}>
          <Form.Group>
            <CustomFormLabel htmlFor="applicantName" required>
              {applicantNameLabel}
            </CustomFormLabel>
            <Field
              id="applicantName"
              name="applicantName"
              as={Form.Control}
              isInvalid={touched.applicantName && !!errors.applicantName}
              placeholder={applicantNamePlaceholder}
              disabled={disableApplicantName}
            />
            <Form.Control.Feedback type="invalid">
              {errors.applicantName}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>

        <Col xs={10} sm={12} lg={6}>
          <Form.Group>
            <CustomFormLabel htmlFor="mothersName" required>
              {mothersNameLabel}
            </CustomFormLabel>
            <Field
              id="mothersName"
              name="mothersName"
              as={Form.Control}
              isInvalid={touched.mothersName && !!errors.mothersName}
              placeholder={mothersNamePlaceholder}
            />
            <Form.Control.Feedback type="invalid">
              {errors.mothersName}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <WysiwygContent
        dangerouslySetInnerHTML={{ __html: statementIntro }}
        className={styles.wysiwyg}
      />
      <Form.Group>
        <Field
          name="terms1"
          type="checkbox"
          id="terms1"
          as={Form.Check}
          isInvalid={touched.terms1 && !!errors.terms1}
          feedback={errors.terms1}
          label={
            <RequiredCheckboxLabel className="d-flex">
              {declareContent ? (
                declareCheckboxLabel
              ) : (
                <WysiwygContent
                  dangerouslySetInnerHTML={{ __html: declareText }}
                  className={styles.wysiwyg}
                />
              )}
            </RequiredCheckboxLabel>
          }
          custom
        />
      </Form.Group>
      {declareContent && (
        <WysiwygContent
          dangerouslySetInnerHTML={{ __html: declareText }}
          className={styles.wysiwyg}
        />
      )}
      <Form.Group>
        <Field
          name="terms2"
          type="checkbox"
          id="terms2"
          as={Form.Check}
          isInvalid={touched.terms2 && !!errors.terms2}
          feedback={errors.terms2}
          label={
            <RequiredCheckboxLabel className="d-flex">
              {agreeContent ? (
                agreeCheckboxLabel
              ) : (
                <WysiwygContent
                  dangerouslySetInnerHTML={{ __html: agreeText }}
                  className={styles.wysiwyg}
                />
              )}
            </RequiredCheckboxLabel>
          }
          custom
        />
      </Form.Group>
      {agreeContent && (
        <WysiwygContent
          dangerouslySetInnerHTML={{ __html: agreeText }}
          className={styles.wysiwyg}
        />
      )}
      <Form.Group>
        <Field
          name="terms3"
          type="checkbox"
          id="terms3"
          as={Form.Check}
          isInvalid={touched.terms3 && !!errors.terms3}
          feedback={errors.terms3}
          label={
            <RequiredCheckboxLabel className="d-flex">
              {understoodContent ? (
                understoodCheckboxLabel
              ) : (
                <WysiwygContent
                  dangerouslySetInnerHTML={{ __html: understoodText }}
                  className={styles.wysiwyg}
                />
              )}
            </RequiredCheckboxLabel>
          }
          custom
        />
      </Form.Group>
      {understoodContent && (
        <WysiwygContent
          dangerouslySetInnerHTML={{ __html: understoodText }}
          className={styles.wysiwyg}
        />
      )}
    </>
  )
}

PositionConsentSubForm.propTypes = {
  statementSectionSublabel: PropTypes.string,
  applicantNameLabel: PropTypes.string,
  applicantNamePlaceholder: PropTypes.string,
  mothersNameLabel: PropTypes.string,
  mothersNamePlaceholder: PropTypes.string,
  statementIntro: PropTypes.string,
  declareCheckboxLabel: PropTypes.string,
  declareText: PropTypes.string,
  agreeCheckboxLabel: PropTypes.string,
  agreeText: PropTypes.string,
  understoodCheckboxLabel: PropTypes.string,
  understoodText: PropTypes.string,
  disableApplicantName: PropTypes.bool,
}

export default PositionConsentSubForm
